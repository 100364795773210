<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :permission="permissionList"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="taskTimeRangeSearch">
        <el-date-picker
          v-model="form.taskTimeRange"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="计划结束时间"
        >
        </el-date-picker>
      </template>
     <template slot="planBeginTimeForm">
        <el-date-picker
          v-model="form.timeRange"
          type="datetimerange"
          :default-time="['00:00:00','23:59:59']"
          range-separator="至"
          @change="setTime"
          start-placeholder="计划开始时间"
          end-placeholder="计划结束时间"
        >
        </el-date-picker>
      </template>
      <template slot="equipmentIdsForm">
        <el-row :span="24">
          <el-col :span="20" style="padding-right: 8px">
            <!-- <el-input disabled v-model="form.equipmentNames"></el-input> -->
            <el-input disabled :value="getName(form.equipmentNames)"></el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" size="small" plain @click="showDialog"
              >选择设备</el-button
            >
          </el-col>
        </el-row>
      </template><template slot="uavId" slot-scope="{ row }">
        <span>{{ row.airPlaneName || "-" }}</span>
      </template>
      <template slot="payloadId" slot-scope="{ row }">
        <span>{{ row.loadName || "-" }}</span>
      </template>
      <template slot="dataLinkId" slot-scope="{ row }">
        <span>{{ row.datalinkName || "-" }}</span>
      </template>
      <template slot="alarmState" slot-scope="{ row }">
        <div class="abnormalCondition" v-if="row.alarmState">有</div>
        <div v-else>无</div>
      </template>
      <!-- 时间组件 -->
      <template slot="mixedTime" slot-scope="scope">
        <div>
          <div>
            <span>{{ scope.row.planBeginTime }} —— </span>
            <span>{{ scope.row.planFinishTime }}</span>
          </div>
          <div>
            <span>{{ scope.row.realBeginTime }} —— </span>
            <span>{{ scope.row.realFinishTime }}</span>
          </div>
        </div>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          v-if="arbitrament('view', scope.row)"
          type="text"
          icon="el-icon-view"
          size="small"
          @click="viewDetail(scope.row)"
        >
          查看
        </el-button>
        <el-button
          v-if="arbitrament('view', scope.row)"
          type="text"
          icon="el-icon-view"
          size="small"
          @click="endTask(scope.row)"
        >
          结束任务
        </el-button>
        <el-button
          v-if="arbitrament('edit', scope.row)"
          type="text"
          icon="el-icon-edit"
          size="small"
          @click="rowEdit(scope.row)"
        >
          编辑
        </el-button>
        <el-button 
          v-if="arbitrament('videoReplay', scope.row)"
          type="text" icon="el-icon-video-camera-solid"
          size="small" @click="viewVideoDetail(scope.row)">
          视频回放
        </el-button> 
        <el-button
          v-if="arbitrament('trackReplay', scope.row)"
          type="text"
          icon="el-icon-discover"
          size="small"
          @click="viewDetail(scope.row)"
        >
          轨迹回放
        </el-button>

        <el-button
          v-if="arbitrament('trackReplay', scope.row)"
          type="text"
          icon="el-icon-download"
          size="small"
          @click="downLoadReport(scope.row)"
        >
          下载报告
        </el-button>

        <el-button
          v-if="arbitrament('del', scope.row)"
          type="text"
          icon="el-icon-delete"
          size="small"
          @click="rowDel(scope.row)"
        >
          删除
        </el-button>
        
        <el-dropdown
          style="display: block"
          @command="onCopy"
          class="copy-btn"
          placement="bottom"
          v-if="arbitrament('view', scope.row)">
          <span 
            class="copy-btn">
            <el-button
              type="text"
              size="small"
              >
              复制推流地址
            </el-button>
            <i class="el-icon-arrow-down task-detail-drodown"></i>
          </span>
          <el-dropdown-menu 
            v-if="(scope.row.liveVideoVOList && Array.isArray(scope.row.liveVideoVOList))? true: false"
            slot="dropdown">
            <el-dropdown-item
              :command="[item, 'push']"
              v-for="item in (scope.row.liveVideoVOList? scope.row.liveVideoVOList: [])"
              :key="item.id">
              {{item.liveDevice}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown
          style="display: block"
          @command="onCopy"
          class="copy-btn"
          placement="bottom"
          v-if="arbitrament('view', scope.row)">
          <span 
            class="copy-btn">
            <el-button
              type="text"
              size="small"
              >
              复制播放地址
            </el-button>
            <i class="el-icon-arrow-down task-detail-drodown"></i>
          </span>
          <el-dropdown-menu 
            v-if="(scope.row.liveVideoVOList && Array.isArray(scope.row.liveVideoVOList))? true: false"
            slot="dropdown">
            <el-dropdown-item
              :command="[item, 'pull']"
              v-for="item in (scope.row.liveVideoVOList? scope.row.liveVideoVOList: [])"
              :key="item.id">
              {{item.liveDevice}}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </avue-crud>

    <el-dialog
      title="设备列表"
      :visible.sync="dialogVisible"
      width="60%"
      :append-to-body="true"
      :before-close="closeDialog"
      :modal="true"
      destroy-on-close
    >
      <FormLayer
        :equipType="equipType"
        :projectId="projectId"
        :teamId="form.teamId"
        v-if="dialogVisible"
        :column="equipColumn"
        @changeSelections="changeSelections"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectEquip">确 定</el-button>
      </div>
    </el-dialog>
    
    <el-dialog
      title="选择设备"
      :visible.sync="equipVisible"
      width="60%"
      :append-to-body="true"
      :before-close="closeEquipDialog"
      :modal="true"
      destroy-on-close
    >
      <el-form ref="routeForm" :model="routeForm" :rules="routeRule">
        <el-form-item
          label="使用飞机"
          label-width="120px"
          prop="historicalAreaId"
        >
          <el-row :span="24">
            <el-col class="route-input" :span="4">
              <el-input
                disabled
                v-model="equipFormNames.uav"
                placeholder="请选择飞机（单选）"
                size="small"
              ></el-input>
              <i
                @click="clearEquip('uav')"
                class="el-icon-delete route-input--deletehis"
              ></i>
            </el-col>
            <el-col :span="4">
              <el-button
                type="primary"
                size="small"
                plain
                @click="chooseEquip('uav')"
                >添加飞机</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="使用载荷"
          label-width="120px"
          prop="historicalAreaId"
        >
          <el-row :span="24">
            <el-col class="route-input" :span="4">
              <el-input
                disabled
                v-model="equipFormNames.pod"
                placeholder="请选择载荷（单选）"
                size="small"
              ></el-input>
              <i
                @click="clearEquip('pod')"
                class="el-icon-delete route-input--deletehis"
              ></i>
            </el-col>
            <el-col :span="4">
              <el-button
                type="primary"
                size="small"
                plain
                @click="chooseEquip('pod')"
                >添加载荷</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="使用数据链"
          label-width="120px"
          prop="historicalAreaId"
        >
          <el-row :span="24">
            <el-col class="route-input" :span="4">
              <el-input
                disabled
                v-model="equipFormNames.datalink"
                placeholder="请选择数据链（单选）"
                size="small"
              ></el-input>
              <i
                @click="clearEquip('datalink')"
                class="el-icon-delete route-input--deletehis"
              ></i>
            </el-col>
            <el-col :span="4">
              <el-button
                type="primary"
                size="small"
                plain
                @click="chooseEquip('datalink')"
                >添加数据链</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeEquip">取 消</el-button>
        <el-button type="primary" @click="submitEquip">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 日志信息 -->
    <el-dialog
      :title="title"
      :visible.sync="logDialogVisible"
      width="30%"
      :before-close="handleLogClose"
      append-to-body
    >
      <div class="snapshoot">
        <template v-if="snapshootList.length">
          <template v-for="item in snapshootList">
            <img v-if="item" :src="item" alt="" :key="item" />
          </template>
        </template>
        <avue-empty description="暂无数据" v-else></avue-empty>
      </div>
    </el-dialog>

    <!-- 轨迹回放 -->
    <DetailDialog
      v-if="detailVisible"
      :dialogData="dialogData"
      :dialogVisible="detailVisible"
      @closeDialog="closeDetail"
    />

    <VideoDialog
      v-if="videoHistoryDialogVisible"
      :taskPlayBackData="taskPlayBackData"
      :videoDialogData="videoDialogData"
      :videoDialogVisible="videoHistoryDialogVisible"
      @videoCloseDialog="videoCloseDialog"

    />
  </basic-container>
</template>

<script>
import {
  getList,
  getDetail,
  add,
  update,
  remove,
  checkRoutes,
  getFlyList,
  getSite,
  assign,
  getHistoryVideo,
  getListByProjectID,
} from "@/api/phugiacloud/projectTask";
import { download } from "@/api/alarm/identify-data";
import { detail, endTask } from "@/api/phugiacloud/operatorTask";
import { mapGetters } from "vuex";
import { getToken } from "@/util/auth";
import { exportExcel } from "@/util/export";
import website from "@/config/website";
import FormLayer from "@/components/form-layer/index";
import DetailDialog from "@/components/task-detail-dialog";
import VideoDialog from "./components/task-video-dialog";

export default {
  name: "FG-records",
  components: {
    FormLayer,
    DetailDialog,
    VideoDialog,
  },
  data() {
    return {
      videoHistoryDialogVisible: false,
      industryTypeArr: [],
      datavConfigVisible: false,
      detailVisible: false,
      configForm: {},
      configRules: {
        screenTitle: [
          { required: true, message: "请输入主标题", trigger: "blur" },
        ],
        industryType: [
          { required: true, message: "请选择行业类型", trigger: "change" },
        ],
      },
      taskId: "",
      snapshootList: [],
      logDialogVisible: false,
      statusMap: {
        // 任务状态(1-未指派;2-待执行;3-执行中;4-已完成)
        1: "未指派",
        2: "待执行",
        3: "执行中",
        4: "已完成",
      },
      lineList: [], //航线列表
      currentCom: "projectTask",
      equipType: "",
      routeForm: {
        historicalAreaId: "",
        routeId: "",
        areaLink: "",
        historicalLink: "",
        selectAreaId: "",
        routeId: "",
      },
      headers: {
        "Blade-Auth": "bearer " + getToken(),
        Authorization: `Basic ${Base64.encode(
          `${website.clientId}:${website.clientSecret}`
        )}`,
      },
      FileListRoute: [],
      fileListArea: [],
      chosenTaskId: "",
      chosenProjectId: "",
      form: {
        projectId: null,
        teamId: null,
        equipmentIds: [],
        equipmentNames: [],
        timeRange: [],
        taskTimeRange: [],
      },
      flyForm: {
        projectId: this.projectId,
        operatorIds: "",
      },
      equipFormNames: {
        uav: "",
        pod: "",
        datalink: "",
      },
      equipForm: {
        uav: "",
        pod: "",
        datalink: "",
      },
      query: {},
      flyOptions: [],
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      equipPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      dialogData: {},
      routeDialog: false,
      dialogVisible: false,
      routesVisible: false,
      videoDialogVisible: false,
      equipVisible: false,
      flyDialog: false,
      flyFormRule: {
        taskName: [
          { required: true, message: "请填写任务名", trigger: "blur" },
        ],
        taskType: [
          { required: true, message: "请选择任务类型", trigger: "blur" },
        ],
        groundStationType: [
          { required: true, message: "请填写使用地面站", trigger: "blur" },
        ],
        planBeginTime: [
          {
            required: true,
            message: "请选择开始时间以及计划结束时间",
            trigger: "blur",
          },
        ],
        planFinishTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        operatorIds: [
          { required: true, message: "请指派飞手", trigger: "blur" },
        ],
      },
      equipColumn: [
        {
          label: "设备序列号",
          prop: "serialNumber",
          search: true,
          display: false,
          rules: [
            {
              required: true,
              message: "请输入序列号",
              trigger: "blur",
            },
          ],
        },
        
        {
          label: "设备名称",
          prop: "equipmentName",
          search: true,
          display: false,
          rules: [
            {
              required: true,
              message: "请输入设备名称",
              trigger: "blur",
            },
          ],
        },
        {
            label: "设备类型",
            prop: "equipmentType",
            dicUrl:
              "/api/blade-system/dict/dictionary-tree?code=equipment_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            type: "tree",
            display: false,
            search: true,
            multiple: true,
            dicFormatter: (res) => {
              if (res.data) {
                for (let i in res.data) {
                  if (res.data[i].children.length !== 0) {
                    res.data[i].disabled = true;
                  }
                }
                return res.data;
              } else {
                return [];
              }
            },
            rules: [
              {
                required: true,
                message: "请选择设备类型",
                trigger: "blur",
              },
            ],
          },

        // {
        //   label: "实体指纹",
        //   prop: "equipmentModel",
        //   display: false,
        //   rules: [
        //     {
        //       required: true,
        //       message: "请输入实体指纹",
        //       trigger: "blur",
        //     },
        //   ],
        // }
      ],
      routeColumn: [
        {
          label: "任务编号",
          prop: "id",
          search: true,
        },
        {
          label: "任务名称",
          prop: "taskName",
          search: true,
        },
        {
          label: "任务类型",
          prop: "taskTypeDesc",
        },
      ],
      selectionList: [],
      data: [],
      equipSelections: [],
      routeSelections: {},
      // 限制结束日期大于开始日期
      pickerOptionsStart: {
        disabledDate: (time) => {
          let endDateVal = this.form.planFinishTime;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          let beginDateVal = this.form.planBeginTime;
          if (beginDateVal) {
            return time.getTime() < new Date(beginDateVal).getTime();
          }
        },
      },
      siteOptions: [],
      //视屏回放当前行数据
      taskPlayBackData: {}
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.projectTask_add, false),
        viewBtn: this.vaildData(this.permission.projectTask_view, false),
        delBtn: this.vaildData(this.permission.projectTask_delete, false),
        editBtn: this.vaildData(this.permission.projectTask_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    projectId() {
      return this.$route.query.projectId;
    },
    teamId() {
      return this.$route.query.teamId;
    },
    option() {
      return {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 4,
        border: true,
        index: true,
        viewBtn: false, //重写查看按钮，自带的不要
        selection: true,
        dialogClickModal: false,
        excelBtn: false,
        columnBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        cellBtn: false,
        excelBtn: true,
        labelWidth: 110,
        menuFixed: "right",
        searchBtnText: "搜索",
        column: [
          {
            label: "任务名称",
            prop: "taskName",
            searchSpan: 5,
            search: true,
            rules: [
              {
                required: true,
                message: "请输入任务名称",
                trigger: "blur",
              },
            ],
          },

          {
            display: false,
            label: "飞行器",
            prop: "uavId",
            slot: true,
          },

          // {
          //   label: "任务类型",
          //   prop: "taskTypeDesc",
          //   display: false,
          // },
          // {
          //   label: "任务类型",
          //   prop: "taskType",
          //   searchSpan: 5,
          //   search: true,
          //   hide: true,
          //   type: "select",
          //   dicUrl: "/api/blade-system/dict/dictionary?code=task_type",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   showColumn: false,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择项目类型",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          {
            label: "执行状态",
            prop: "taskStatus",
            searchSpan: 5,
            search: true,
            hide: true,
            addDisplay: false,
            editDisplay: false,
            type: "select",
            dicData: [{
            label: '未指派',
            value: 1
             }, {
            label: '待执行',
            value: 2
            }, {
            label: '执行中',
            value: 3
            }, {
            label: '已完成',
            value: 4
            }
          
          ],
            showColumn: false,
            rules: [
              {
                required: true,
                message: "请选择项目状态",
                trigger: "blur",
              },
            ],
          },
          {
            label: "执行状态",
            prop: "taskStatusName",
            display: false,
            width: 80,
          },
          // {
          //   label: "使用地面站",
          //   width: 85,
          //   prop: "groundStationType",
          //   type: "select",
          //   dicUrl:
          //     "/api/blade-system/dict-biz/dictionary?code=GroundStationType",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey",
          //   },
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择使用地面站",
          //       trigger: "blur",
          //     },
          //   ],
          // },

          {
            label: "计划/实际执行时间",
            prop: "mixedTime",
            span: 24,
            width: 280,
            display: false,
          },
          
          {
            label: "实际飞行时长",
            prop: "flyTime",
            searchSpan: 10,
            width: 120,
            display: false,
            formatter:(val,value,label)=>{
              var hours = Math.floor(value / 3600);
              var minutes = Math.floor((value - (hours * 3600)) / 60);
              var remainingSeconds = value - (hours * 3600) - (minutes * 60);

              if (hours < 10) {
                hours = "0" + hours;
              }

              if (minutes < 10) {
                minutes = "0" + minutes;
              }

              if (remainingSeconds < 10) {
                remainingSeconds = "0" + remainingSeconds;
              }

              return hours + "h" + minutes + "m" + remainingSeconds+"s";

            }
          },
          {
            label: "实际飞行里程",
            prop: "flyDistance",
            searchSpan: 10,
            display: false,
            formatter:(val,value,label)=>{
              if (!value) return "0km";
              return (value/ 1000).toFixed(2) + "km";
            }
          },
          {
            prop: "alarmState",
            label: "有无报警",
            slot: true,
            search: false,
            display: false,
          },
          {
            label: "飞手",
            placeholder: "请选择任务指派飞手",
            prop: "operatorIds",
            multiple: true,
            limit: 1,
            type: "select",
            dicUrl: "/api/cloud-user/fly-operator/page?current=1&size=9999",
            dicFormatter: (res) => {
              return res.data.records || [];
            },
            props: {
              label: "realName",
              value: "userId",
            },
            showColumn: false,
          },
          {
            label: "所属团体",
            width: 120,
            prop: "teamId",
            type: "tree",
            dataType: "number",
            dicUrl: "/api/blade-system/dept/tree",
            props: {
              label: "title",
              value: "id",
            },
            rules: [
              {
                required: true,
                message: "请选择任务所属团体",
                trigger: "blur",
              },
            ],
          },

          {
            display: false,
            label: "载荷",
            prop: "payloadId",
            slot: true,
          },
          {
            label: "数据链",
            display: false,
            prop: "dataLinkId",
            slot: true,
          },
          {
            label: "任务编号",
            prop: "id",
            width: 90,
            display: false,
          },
          // {
          //   label: "执行任务人",
          //   prop: "memberIds",
          //   type: "select",
          //   multiple: true,
          //   hide: true,
          //   dicUrl: `/api/cloud-task/project-management/memberDict?id=${this.projectId}`,
          //   props: {
          //     label: "label",
          //     value: "value",
          //   },
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择执行任务人",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          {
            label: "任务时间",
            hide: true,
            prop: "planBeginTime",
            type: "datetime",
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            rules: [
              {
                required: true,
                message: "请选择开始时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "使用设备",
            prop: "equipmentIds",
            formslot: true,
            hide: true,
            rules: [
              {
                required: true,
                message: "请选择使用设备",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    }
  },
  mounted() {
    Promise.all([
      this.getSite()
      // this.getListByProjectID()
    ]).then(() => {
      const  { taskId, from } = this.$route.query;
      if (from === 'detail' || from === 'list') {
        const params = {
          id: taskId
        };
        detail(params).then((res) => {
          if (res.data.code === 200) {
            const { groundStationType } = res.data.data;
            const { pageType } = this.$route.query;
            this.dialogData = Object.assign(res.data.data, {
              pageType,
              groundStationType,
            });
            this.detailVisible = true;
          }
        });
      }
    });
  },
  methods: {
    handleClose() {
      this.$refs.configForm.resetFields();
      this.datavConfigVisible = false;
      this.configForm = {};
    },

    onCopy(arr) {
      let [ node, type] = arr
      const text = type === 'push'? node.pushStreamUrl: node.webrtcPullStreamUrl
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
          .then(() => {
            this.$message.success('复制成功');
          })
          .catch(() => {
            this.$message.error('复制失败');
          });
      } else {
        // IE
        try {
          var textarea = document.createElement('textarea');
          textarea.style.position = 'fixed';
          textarea.style.opacity = 0;
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
          this.$message.success('复制成功');
        } catch (error) {
          this.$message.error('复制失败，建议使用chrome浏览器');
        }
      }
    },

    // toDatav(row) {
    //   const {
    //     industryType,
    //     screenTitle,
    //     screenSubTitle,
    //     flyOperatorName,
    //     groundStationType,
    //     tlfp,
    //     uavID,
    //   } = row;
    //   this.$router.push({
    //     path:
    //       industryType === "ForestandGrassPatrolInspection"
    //         ? "/forest-datav"
    //         : "/petroleum-datav",
    //     query: {
    //       id: row.id,
    //       industryType,
    //       screenTitle,
    //       screenSubTitle,
    //       projectId: this.projectId,
    //       flyOperatorName,
    //       groundStationType,
    //       tlfp,
    //       uavID,
    //     },
    //   });
    // },

    getName(names) {
      if (!names || !Array.isArray(names)) return "";
      return names.filter((n) => !!n);
    },
    rowEdit(row) {
      this.$refs.crud.rowEdit(row);
    },
    arbitrament(type, row) {
      const { taskStatus, groundStationType } = row;
      /*
        任务状态(1-未指派;2-待执行;3-执行中;4-已完成);

        操作[编辑-edit，删除-del，指派-giveWork，下发-send,查看-view，结束任务-finish，日志-log，轨迹回放-trackReplay，视频回放-videoReplay]

        未指派 [编辑、删除、指派任务]
        待执行 [编辑、删除、下发到地面站（老地面站不执行下发，仅作为开始任务的按钮使用）]、
        执行中 [查看、结束任务、日志（仅新地面站支持，分为：人工检查、数据快照、系统自检；老地面站、大疆暂时隐藏）]
        已完成 [视频回放（航电的“任务回放”名称更改为：视频回放）、轨迹回放、日志（仅新地面站支持，分为：人工检查、数据快照、系统自检；老地面站、大疆暂时隐藏）]
      */
      switch (type) {
        case "edit":
          if (taskStatus === 1 || taskStatus === 2) {
            return true;
          }
          return false;
        case "del":
          if (taskStatus === 1 || taskStatus === 2) {
            return true;
          }
          return false;
        case "giveWork":
          if (taskStatus === 1) {
            return true;
          }
          return false;
        case "send":
          if (taskStatus === 2) {
            return true;
          }
          return false;
        case "view":
          if (taskStatus === 3) {
            return true;
          }
          return false;
        case "finish":
          if (taskStatus === 3) {
            return true;
          }
          return false;
        case "log":
          if (
            (taskStatus === 3 || taskStatus === 4) &&
            groundStationType === "PhugiaOS"
          ) {
            return true;
          }
          return false;
        case "trackReplay":
          if (taskStatus === 4) {
            return true;
          }
          return false;
        case "videoReplay":
          if (taskStatus === 4) {
            return true;
          }
          return false;
        default:
          return false;
      }
    },
    // getListByProjectID() {
    //   return new Promise((resolve, reject) => {
    //     getListByProjectID(this.projectId).then((res) => {
    //       this.lineList = res.data.data;
    //       resolve();
    //     });
    //   });
    // },
    // 搜索时间
    dateChange(val) {
      if (val) {
        const params = {
          ...this.query,
          startTime: val[0],
          endTime: val[1],
        };
        this.onLoad(this.page, params);
      }
    },
    // 获取使用地面站
    getSite() {
      return new Promise((resolve)=>{
        getSite().then((res) => {
          if (res.data.code === 200) {
            this.siteOptions = res.data.data;
            resolve()
          }
        });
      })
    },
    // 使用地面站
    getTaskSite(val) {
      this.form = Object.assign(this.form, this.siteOptions[val]);
      this.chosenTaskId = this.siteOptions[val].taskSiteId;
    },
    // 导出功能
    handleExportExcel() {
      const option = {
        title: "飞行任务列表",
        columns: [
          {
            label: "任务编号",
            prop: "id",
          },
          {
            label: "任务名称",
            prop: "taskName",
          },
          {
            label: "任务类型",
            prop: "taskTypeDesc",
          },
          {
            label: "行业类型",
            prop: "$industryType",
          },
          {
            label: "使用地面站",
            prop: "$groundStationType",
          },
          {
            label: "航线名称",
            prop: "taskRouteName",
          },
          {
            label: "飞手",
            prop: "flyOperatorName",
          },
          {
            label: "任务所属团队",
            prop: "$teamId",
          },
          {
            label: "飞行器",
            prop: "airPlaneName",
          },
          {
            label: "载荷",
            prop: "loadName",
          },
          {
            label: "数据链",
            prop: "datalinkName",
          },
          {
            label: "执行状态",
            prop: "taskStatusName",
          },
          {
            label: "计划开始时间",
            prop: "planBeginTime",
          },
          {
            label: "计划结束时间",
            prop: "planFinishTime",
          },
        ],
      };
      const _this = this;
      exportExcel(option, _this);
    },
    // 指派任务
    giveWork(row) {
      this.flyForm.operatorIds = "";
      this.flyForm = row;
      if (row.operatorIds instanceof Array) {
        this.flyForm.operatorIds = row.operatorIds[0];
      } else {
        this.flyForm.operatorIds = row.operatorIds;
      }
      getFlyList().then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data.records;
          this.flyOptions = [];
          data.map((item) => {
            this.flyOptions.push({
              label: item.realName,
              value: item.userId,
            });
          });
        }
      });
      this.flyDialog = true;
    },


    // 视频回放
    viewVideoDetail(row) {
      getHistoryVideo(row.id).then((res) => {
        this.taskPlayBackData = row;
        this.videoDialogData = res.data.data
        if (res.data.code == 200) {
          if (this.hasVideoURL(this.videoDialogData)) {
            this.videoHistoryDialogVisible = true;
            return
          }
          if (!this.videoHistoryDialogVisible) {
            this.$message({
              message: "此任务暂无视频回放，请核实",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "此任务暂无视频回放，请核实",
            type: "warning",
          });
        }
      });
    },

    hasVideoURL(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (Array.isArray(item.historyVideoVOList)) {
          let urlList = item.historyVideoVOList
          for (let j = 0; j < urlList.length; j++) {
            return true
          }
        }
      }
      return false
    },

    // 确认指派
    taskflyOperate() {
      this.$refs["flyForm"].validate((valid) => {
        if (valid) {
          const data = {
            operatorIds: [this.flyForm.operatorIds],
            taskId: this.flyForm.id,
          };
          assign(data)
            .then((res) => {
              if (res.data.code === 200) {
                this.$message.success("任务指派成功！");
                this.flyDialog = false;
              }
            })
            .then(() => {
              this.onLoad(this.page, this.query);
            });
        } else {
          return false;
        }
      });
    },
    routeFormInit() {
      this.routeForm = {
        historicalAreaId: "",
        routeId: "",
        areaLink: "",
        historicalLink: "",
        // selectAreaId: "",
        routeId: "",
      };
    },
    // 对比航线
    checkRoutes() {
      this.$refs["routeForm"].validate((valid) => {
        if (valid) {
          if (this.routeForm.routeId && this.routeForm.historicalAreaId) {
            this.$message.warning("历史航线与上传航线二者选填一，请核对！");
          } else {
            if (this.routeForm.historicalLink) {
              const params = {
                orgUrl: this.routeForm.areaLink,
                compUrl: this.routeForm.historicalLink,
              };
              let ifSuccess = false;
              checkRoutes(params)
                .then((res) => {
                  ifSuccess = res.data.data;
                })
                .then(() => {
                  if (ifSuccess) {
                    this.form.taskAreaId = this.routeForm.historicalAreaId;
                    this.form.taskRouteId = this.routeForm.historicalRouteId;
                    this.routesVisible = false;
                    this.routeFormInit();
                  } else {
                    this.$message.warning("航线轨迹对比不一致，请上传航线！");
                    this.routeForm.historicalAreaId = "";
                    this.routeForm.historicalLink = "";
                    this.routeSelections = "";
                  }
                });
            } else {
              // this.form.taskAreaId = this.routeForm.selectAreaId;
              this.form.taskRouteId = this.routeForm.routeId;
              this.routesVisible = false;
              this.routeFormInit();
            }
          }
        } else {
          return false;
        }
      });
    },
    changeArea(res) {
      if (res.code === 200) {
        // this.routeForm.selectAreaId = res.data.attachId;
        this.routeForm.areaLink = res.data.link;
        // this.$refs['routeForm'].validate((valid) => {
        //   if (valid) {
        //     this.$refs['routeForm'].clearValidate('selectAreaId');
        //   }
        // });
      }
    },
    changeRoutes(res) {
      if (res.code === 200) {
        this.routeForm.routeId = res.data.attachId;
        this.routeForm.routeLink = res.data.link;
      }
    },
    equipInfoInit() {
      this.equipFormNames = {
        uav: "",
        pod: "",
        datalink: "",
      };
      this.equipForm = {
        uav: "",
        pod: "",
        datalink: "",
      };
    },
    // 确认选择设备
    submitEquip() {
      if (!this.equipForm.uav) {
        this.$message.error("请选择使用飞机!");
        return;
      }
      this.form.equipmentIds = [];
      this.form.equipmentNames = [];

      for (let i in this.equipForm) {
        this.form.equipmentIds.push(this.equipForm[i]);
      }
      for (let i in this.equipFormNames) {
        this.form.equipmentNames.push(this.equipFormNames[i]);
      }
      this.equipVisible = false;
      this.equipInfoInit();
    },
    // 关闭设备选择
    closeEquip() {
      this.routesVisible = false;
      this.equipInfoInit();
      this.equipSelections = [];
      this.routeSelections = "";
      this.equipVisible = false;
    },
    // 清除一条内容
    clearEquip(type) {
      this.equipForm[type] = "";
      this.equipFormNames[type] = "";
    },
    // 清空航线
    clearRoutes() {
      this.form.taskRouteId = null;
    },
    // 清空历史航线
    clearHistoryRoutes() {
      this.routeForm.historicalAreaId = null;
    },
    // 选择历史航线
    chooseHisRoute() {
      if (this.routeForm.routeId) {
        this.$message.warning("历史航线与上传航线二者选填一，请核对！");
        return false;
      } else {
        this.routeDialog = true;
        this.routeForm.historicalAreaId = "";
        this.routeForm.historicalLink = "";
        this.routeForm.historicalRouteId = "";
        this.routeSelections = "";
      }
    },
    handleRemoveArea(file, fileList) {
      // this.routeForm.selectAreaId = null;
      this.routeForm.areaLink = null;
    },
    handleRemoveRoute(file, fileList) {
      this.routeForm.routeId = null;
      this.routeForm.routeLink = null;
    },
    // 核对航线
    handleCheckHis() {
      if (this.routeForm.historicalAreaId) {
        this.$message.warning("历史航线与上传航线二者选填一，请核对！");
        return false;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    showDialog() {
      //选择设备，代入项目id
      const { projectId } = this.$route.query;
      if (this.form.equipmentNames && this.form.equipmentNames.length) {
        if (this.form.equipmentItem) {
          if (this.form.equipmentItem.airs) {
            this.equipFormNames.uav =
              this.form.equipmentItem.airs.equipmentName;
            this.equipForm.uav = this.form.equipmentItem.airs.equipmentId;
          }
          if (this.form.equipmentItem.loads) {
            this.equipFormNames.pod =
              this.form.equipmentItem.loads.equipmentName;
            this.equipForm.pod = this.form.equipmentItem.loads.equipmentId;
          }
          if (this.form.equipmentItem.datalinks) {
            this.equipFormNames.datalink =
              this.form.equipmentItem.datalinks.equipmentName;
            this.equipForm.datalink =
              this.form.equipmentItem.datalinks.equipmentId;
          }
        }
      } else {
        this.equipInfoInit();
      }
      this.equipVisible = true;
    },
    showRoutes() {
      this.FileListRoute = [];
      this.FileListArea = [];
      this.routesVisible = true;
      this.$nextTick(() => {
        this.$refs["routeForm"].clearValidate();
      });
    },
    changeSelections(params) {
      const type = params.equipType;
      if (params.selectionList.length) {
        const list = params.selectionList;
        this.equipForm[type] = list[0].id;
        this.equipFormNames[type] = list[0].equipmentName;
      } else {
        this.equipForm[type] = "";
        this.equipFormNames[type] = "";
      }
      console.log(this.equipForm, "equipForm");
      console.log(this.equipFormNames, "equipFormNames");
    },
    changeRouteSelections(list) {
      if (list.length === 0) {
        this.routeSelections = {};
      } else {
        this.routeSelections = list;
      }
    },
    selectEquip() {
      this.form.equipmentIds = [];
      this.form.equipmentNames = [];
      this.dialogVisible = false;
    },
    selectRoute() {
      if (this.routeSelections.length) {
        this.routeForm.historicalAreaId = this.routeSelections[0].taskAreaId;
        this.routeForm.historicalLink = this.routeSelections[0].taskAreaFileUrl;
        this.routeForm.historicalRouteId = this.routeSelections[0].taskRouteId;
      } else {
        this.routeForm.historicalAreaId = "";
        this.routeForm.historicalLink = "";
        this.routeForm.historicalRouteId = "";
      }
      this.routeDialog = false;
    },
    // 设置选择时间
    setTime(value) {
      if (value) {
        this.form.planBeginTime = value[0].getTime();
        this.form.planFinishTime = value[1].getTime();
      } else {
        this.form.planBeginTime = null;
        this.form.planFinishTime = null;
      }
    },

    downLoadReport(row) {
      this.$confirm("是否下载该报告?", "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        download(row.id).then((r) => {
          let index = r.headers["content-disposition"].indexOf("=") + 1;
          let fileName = decodeURIComponent(
            r.headers["content-disposition"].substr(index)
          );
          const blob = r.data;
          let link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([blob], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
        });
      });
    },

    chooseEquip(type) {
      this.equipType = type;
      this.dialogVisible = true;
    },
    // 查看详情
    viewDetail(row, status) {
      const { groundStationType } = row;
      const params = {
        id: row.id,
      };
      detail(params).then((res) => {
        if (res.data.code === 200) {
          const { pageType } = this.$route.query;
          this.dialogData = Object.assign(res.data.data, {
            pageType,
            groundStationType,
          });
          this.detailVisible = true;
        }
      });
      // done()
    },
    // 结束任务
    endTask(row) {
      endTask({id: row.id}).then(res=>{
        if (res.data.code === 200) {
          this.$message.success('任务已结束')
          this.onLoad(this.page);
        }
      })
    },
    closeDialog(done) {
      this.dialogVisible = false;
      done();
    },
    closeDetail() {
      const  { from } = this.$route.query;
      this.detailVisible = false;
      if (from === 'detail') {
        // 跳转路由
        this.$router.push('/mission-center/item-manager-config')
        // 通知首页切换到项目列表
        this.$EventBus.$emit('toProjectList')
      }
      
    },

    videoCloseDialog(done) {
      this.videoHistoryDialogVisible = false;
      done();
    },

    closeRoutes() {
      this.routesVisible = false;
      this.routeFormInit();
      this.equipSelections = [];
      this.routeSelections = "";
    },
    closeEquipDialog() {
      this.equipVisible = false;
    },
    closeRouteDialog(done) {
      this.routeDialog = false;
      done();
    },
    closeFlyDialog(done) {
      this.flyDialog = false;
      done();
    },
    sendMsgtoOne() {
      this.$router.push({
        query: {},
      });
    },
    rowSave(row, done, loading) {
      row.projectId = this.projectId;
      // row.teamId = this.teamId;
      const { payloadId, uavId, equipmentIds } = row;
      const airPlaneID = equipmentIds[0];
      const loadID = equipmentIds[1];
      const dataLinkID = equipmentIds[2];
      // equipmentIds:['飞机','载荷','数据链']
      add({
        ...row,
        loadPrint: payloadId,
        planePrint: uavId,
        airPlaneID,
        loadID,
        dataLinkID,
      }).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    async beforeOpen(done, type) {
      if (["add"].includes(type)) {
        this.form.teamId = this.teamId;
      }
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
          this.form.equipmentNames = res.data.data.equipmentName.split(",");
          this.form.timeRange = [
            new Date(this.form.planBeginTime),
            new Date(this.form.planFinishTime),
          ];
        });
      }
      done();
      // Promise.all([this.getListByProjectID()]).then(() => {
      //   done();
      // });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (this.form.taskTimeRange && this.form.taskTimeRange.length) {
        params = {
          ...params,
          startTime: this.form.taskTimeRange[0],
          endTime: this.form.taskTimeRange[1],
        };
      }
      params.projectId = this.projectId;
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done()
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      this.query.projectId = this.projectId;
      params.taskBackgroundRequest=true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
        this.$nextTick(() => {
          this.$refs.crud.doLayout();
        });
      });
    },
  },
};
</script>

<style lang="scss">
.route-input {
  position: relative;

  &:hover {
    .route-input--delete {
      visibility: visible;
    }

    .route-input--deletehis {
      visibility: visible;
    }
  }

  &--delete {
    visibility: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 9;
  }

  &--deletehis {
    visibility: hidden;
    position: absolute;
    top: 14px;
    right: 10px;
    cursor: pointer;
    z-index: 9;
  }
}
.snapshoot {
  min-height: 200px;
  max-height: 580px;
  overflow: scroll;

  & > img {
    width: 100%;
    display: block;
  }

  .avue-empty__image {
    display: flex;
    justify-content: center;
  }
}

.abnormalCondition {
  text-align: center;
  width: 55px;
  height: 24px;
  border-radius: 12px;
  color: red;
  background-color: rgba(255, 0, 0, 0.3);
  margin: 0 auto;
}
.task-detail-drodown {
  color: #409EFF;
}
</style>
