var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen,
          },
          on: {
            "update:page": function ($event) {
              _vm.page = $event
            },
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "row-del": _vm.rowDel,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
          },
          scopedSlots: _vm._u([
            {
              key: "uavId",
              fn: function (ref) {
                var row = ref.row
                return [_c("span", [_vm._v(_vm._s(row.airPlaneName || "-"))])]
              },
            },
            {
              key: "payloadId",
              fn: function (ref) {
                var row = ref.row
                return [_c("span", [_vm._v(_vm._s(row.loadName || "-"))])]
              },
            },
            {
              key: "dataLinkId",
              fn: function (ref) {
                var row = ref.row
                return [_c("span", [_vm._v(_vm._s(row.datalinkName || "-"))])]
              },
            },
            {
              key: "alarmState",
              fn: function (ref) {
                var row = ref.row
                return [
                  row.alarmState
                    ? _c("div", { staticClass: "abnormalCondition" }, [
                        _vm._v("有"),
                      ])
                    : _c("div", [_vm._v("无")]),
                ]
              },
            },
            {
              key: "mixedTime",
              fn: function (scope) {
                return [
                  _c("div", [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.planBeginTime) + " —— "),
                      ]),
                      _c("span", [_vm._v(_vm._s(scope.row.planFinishTime))]),
                    ]),
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.realBeginTime) + " —— "),
                      ]),
                      _c("span", [_vm._v(_vm._s(scope.row.realFinishTime))]),
                    ]),
                  ]),
                ]
              },
            },
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-view",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        查看\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-view",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.endTask(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        结束任务\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("edit", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-edit",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.rowEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        编辑\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("videoReplay", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-video-camera-solid",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewVideoDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        视频回放\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("trackReplay", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-discover",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.viewDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        轨迹回放\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("trackReplay", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-download",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.downLoadReport(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        下载报告\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("del", scope.row)
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-delete",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.rowDel(scope.row)
                            },
                          },
                        },
                        [_vm._v("\n        删除\n      ")]
                      )
                    : _vm._e(),
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "copy-btn",
                          staticStyle: { display: "block" },
                          attrs: { placement: "bottom" },
                          on: { command: _vm.onCopy },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "copy-btn" },
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "text", size: "small" } },
                                [
                                  _vm._v(
                                    "\n            复制推流地址\n          "
                                  ),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down task-detail-drodown",
                              }),
                            ],
                            1
                          ),
                          (
                            scope.row.liveVideoVOList &&
                            Array.isArray(scope.row.liveVideoVOList)
                              ? true
                              : false
                          )
                            ? _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  scope.row.liveVideoVOList
                                    ? scope.row.liveVideoVOList
                                    : [],
                                  function (item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: item.id,
                                        attrs: { command: [item, "push"] },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item.liveDevice) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.arbitrament("view", scope.row)
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "copy-btn",
                          staticStyle: { display: "block" },
                          attrs: { placement: "bottom" },
                          on: { command: _vm.onCopy },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "copy-btn" },
                            [
                              _c(
                                "el-button",
                                { attrs: { type: "text", size: "small" } },
                                [
                                  _vm._v(
                                    "\n            复制播放地址\n          "
                                  ),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down task-detail-drodown",
                              }),
                            ],
                            1
                          ),
                          (
                            scope.row.liveVideoVOList &&
                            Array.isArray(scope.row.liveVideoVOList)
                              ? true
                              : false
                          )
                            ? _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                _vm._l(
                                  scope.row.liveVideoVOList
                                    ? scope.row.liveVideoVOList
                                    : [],
                                  function (item) {
                                    return _c(
                                      "el-dropdown-item",
                                      {
                                        key: item.id,
                                        attrs: { command: [item, "pull"] },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(item.liveDevice) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "taskTimeRangeSearch" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "计划结束时间",
                },
                model: {
                  value: _vm.form.taskTimeRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskTimeRange", $$v)
                  },
                  expression: "form.taskTimeRange",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "planBeginTimeForm" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "default-time": ["00:00:00", "23:59:59"],
                  "range-separator": "至",
                  "start-placeholder": "计划开始时间",
                  "end-placeholder": "计划结束时间",
                },
                on: { change: _vm.setTime },
                model: {
                  value: _vm.form.timeRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "timeRange", $$v)
                  },
                  expression: "form.timeRange",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "equipmentIdsForm" },
            [
              _c(
                "el-row",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-right": "8px" },
                      attrs: { span: 20 },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                          value: _vm.getName(_vm.form.equipmentNames),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small", plain: "" },
                          on: { click: _vm.showDialog },
                        },
                        [_vm._v("选择设备")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设备列表",
            visible: _vm.dialogVisible,
            width: "60%",
            "append-to-body": true,
            "before-close": _vm.closeDialog,
            modal: true,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("FormLayer", {
                attrs: {
                  equipType: _vm.equipType,
                  projectId: _vm.projectId,
                  teamId: _vm.form.teamId,
                  column: _vm.equipColumn,
                },
                on: { changeSelections: _vm.changeSelections },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.selectEquip } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择设备",
            visible: _vm.equipVisible,
            width: "60%",
            "append-to-body": true,
            "before-close": _vm.closeEquipDialog,
            modal: true,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.equipVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "routeForm",
              attrs: { model: _vm.routeForm, rules: _vm.routeRule },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "使用飞机",
                    "label-width": "120px",
                    prop: "historicalAreaId",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "route-input", attrs: { span: 4 } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "请选择飞机（单选）",
                              size: "small",
                            },
                            model: {
                              value: _vm.equipFormNames.uav,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipFormNames, "uav", $$v)
                              },
                              expression: "equipFormNames.uav",
                            },
                          }),
                          _c("i", {
                            staticClass:
                              "el-icon-delete route-input--deletehis",
                            on: {
                              click: function ($event) {
                                return _vm.clearEquip("uav")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseEquip("uav")
                                },
                              },
                            },
                            [_vm._v("添加飞机")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "使用载荷",
                    "label-width": "120px",
                    prop: "historicalAreaId",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "route-input", attrs: { span: 4 } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "请选择载荷（单选）",
                              size: "small",
                            },
                            model: {
                              value: _vm.equipFormNames.pod,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipFormNames, "pod", $$v)
                              },
                              expression: "equipFormNames.pod",
                            },
                          }),
                          _c("i", {
                            staticClass:
                              "el-icon-delete route-input--deletehis",
                            on: {
                              click: function ($event) {
                                return _vm.clearEquip("pod")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseEquip("pod")
                                },
                              },
                            },
                            [_vm._v("添加载荷")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "使用数据链",
                    "label-width": "120px",
                    prop: "historicalAreaId",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "route-input", attrs: { span: 4 } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "请选择数据链（单选）",
                              size: "small",
                            },
                            model: {
                              value: _vm.equipFormNames.datalink,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipFormNames, "datalink", $$v)
                              },
                              expression: "equipFormNames.datalink",
                            },
                          }),
                          _c("i", {
                            staticClass:
                              "el-icon-delete route-input--deletehis",
                            on: {
                              click: function ($event) {
                                return _vm.clearEquip("datalink")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.chooseEquip("datalink")
                                },
                              },
                            },
                            [_vm._v("添加数据链")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeEquip } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitEquip } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.logDialogVisible,
            width: "30%",
            "before-close": _vm.handleLogClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.logDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "snapshoot" },
            [
              _vm.snapshootList.length
                ? [
                    _vm._l(_vm.snapshootList, function (item) {
                      return [
                        item
                          ? _c("img", {
                              key: item,
                              attrs: { src: item, alt: "" },
                            })
                          : _vm._e(),
                      ]
                    }),
                  ]
                : _c("avue-empty", { attrs: { description: "暂无数据" } }),
            ],
            2
          ),
        ]
      ),
      _vm.detailVisible
        ? _c("DetailDialog", {
            attrs: {
              dialogData: _vm.dialogData,
              dialogVisible: _vm.detailVisible,
            },
            on: { closeDialog: _vm.closeDetail },
          })
        : _vm._e(),
      _vm.videoHistoryDialogVisible
        ? _c("VideoDialog", {
            attrs: {
              taskPlayBackData: _vm.taskPlayBackData,
              videoDialogData: _vm.videoDialogData,
              videoDialogVisible: _vm.videoHistoryDialogVisible,
            },
            on: { videoCloseDialog: _vm.videoCloseDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }