<template>
  <basic-container>
    <el-dialog
      :title="$t('mission.flightMissionDetails')"
      :visible.sync="videoDialogVisible"
      width="80%"
      class="task-video-dialog"
      :fullscreen="true"
      :append-to-body="true"
      :before-close="videoCloseDialog"
      destroy-on-close
      :modal="false"
    >
      <div class="content">
        <div class="taskPlayBack-header">
          <div>
            <span class="title-modified"></span>
            {{$t('mission.MissionDetails')}}
          </div>
          <div class="taskPlayBack-header-content">
            <div>
              <span>任务名称: {{ taskPlayBackData.taskName }}</span>
              <span>航线名称: {{ taskPlayBackData.taskRouteName }}</span>
            </div>
            <div>
              <span>任务类型: {{ taskType }}</span>
              <span>设备名称: {{ taskPlayBackData.equipmentNames }}</span>
            </div>
            <div>
              <span>开始时间: {{ taskPlayBackData.flyBeginTime }}</span>
              <span>结束时间: {{ taskPlayBackData.flyFinishTime }}</span>
            </div>
            <div>
              <span>飞手: {{ taskPlayBackData.operatorName }}</span>
            </div>
          </div>
        </div>
        <div class="taskPlayBack-video">
          <div class="taskPlayBack-video-title">
            <span class="title-modified"></span>
            任务回放
          </div>
          <div class="taskVideoSelect">
            <template>
              <span>请选择播放源： </span>
              <el-cascader
                v-model="value"
                :options="options"
                style="width: 300px"
                @change="historyVideoChange">
              </el-cascader>
            </template>
          </div>
          <div class="taskVideoInformation">
            <div>视频名称：{{ historyVideoData.videoName }}</div>
            <div>播放地址：{{ historyVideoData.url }}</div>
            <div>视频文件大小：{{ historyVideoData.size }}</div>
          </div>
        </div>
        <div class="videoPlayer">
          <div class="videoPlayer-inner" @mouseover="isDownload = true" @mouseout="isDownload = false">
            <div class="download" v-show="isDownload && historyVideoUrl">
              <el-button type="info" circle icon="el-icon-download" @click="downLoadVideo"></el-button>
            </div>
            <img height="100%"  src="/img/live-bg.png" alt="">
            <xg-player
              class="videoPlayer-xg-player"
              ref="videoLive"
              :videoType="type"
              :videoUrl="historyVideoUrl"
              :screen="false"
              :show="true"/>
          </div>
        </div>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import XgPlayer from '@/components/video-live-xgplayer';
import { getDictionary } from "@/api/system/dict";
export default {
  components: { XgPlayer },
  data() {
    return {
      // 用于存放视频信息
      historyVideoData: {},
      // 任务类型
      taskType: "",
      // 级联选择器
      value: [0, 0],
      // 展示视频下载
      isDownload: false
    };
  },
  props: {
    // 当前视频table的行数据
    taskPlayBackData: {
      type: Object,
      default() {
        return {};
      },
    },
    // 具体某个任务视频流业务数据
    videoDialogData: {
      type: Array,
      default() {
        return [];
      },
    },
    // 弹窗显示和关闭
    videoDialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    // 播放地址
    historyVideoUrl() {
      return this.videoDialogData[this.value[0]].historyVideoVOList[this.value[1]].url
    },
    // 级联选择器选项
    options() {
      return this.videoDialogData.map((item, index)=>{
        return {
          value: index,
          label: item.liveDevice,
          children: item.historyVideoVOList.map((_item, index)=>{
            return {
              value: index,
              label: _item.videoName
            }
          })
        }
      })
    },
    type() {
      let videoFileData = this.videoDialogData[this.value[0]].historyVideoVOList[this.value[1]]
      let videoFileType = videoFileData.videoFileType
      if (!videoFileType) videoFileType = videoFileData.url.slice(-3)
      return videoFileType
    }
  },
  created() {
    this.selectVideoURL(this.videoDialogData) 
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getDictionary({ code: "task_type" }).then((res) => {
        const taskTypeList = res.data.data;
        taskTypeList.forEach((item) => {
          if (item.dictKey === this.taskPlayBackData.taskType) {
            this.taskType = item.dictValue;
          }
        });
      });
    },
    selectVideoURL(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (Array.isArray(item.historyVideoVOList)) {
          let urlList = item.historyVideoVOList
          for (let j = 0; j < urlList.length; j++) {
            this.value = [i , j]
            return
          }
        }
      }
      return false
    },
    videoCloseDialog(done) {
      this.$emit("videoCloseDialog", done);
    },
    historyVideoChange() {
      this.$nextTick(()=>{
        this.$refs.videoLive.switchURL()
      })
    },
    downLoadVideo() {
      if (!this.historyVideoUrl) {
        return this.$message.warning(this.$t('noVideoUrl'));
      }
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = this.historyVideoUrl;
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(this.historyVideoUrl);
    }
  }
};
</script>

<style lang="scss">
.task-video-dialog {
  .el-dialog.is-fullscreen {
    background-color: #000;
    display: flex;
    flex-direction: column;
    .el-dialog__title {
      color: #fff;
      font-weight: bold;
    }
    .el-dialog__body {
      flex-grow: 1
    }
  }
  .content {
    color: #fff;
    height: 100%;
    .taskPlayBack-header {
      color: #fff;
      height:  90px;
      .taskPlayBack-header-content {
        color: white;
        font-size: 14px;
        display: flex;
        margin: 12px 0;
        div {
          margin-right: 40px;
          span {
            display: block;
          }
        }
      }
    }
    .taskPlayBack-video {
      color: white;
      background: rgba(0, 0, 0, 0.6);
      box-sizing: border-box;
      height:  120px;
    }
    .videoPlayer {
      padding-top: 1px;
      height: calc(100% - 208px);
      display: flex;
      justify-content: center;
      .videoPlayer-inner {
        box-sizing: border-box;
        height: 100%;
        position: relative;
        border: #333 1px solid;
        .videoPlayer-xg-player {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
        .download {
          color: #fff;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          z-index: 100;
          cursor: pointer;
          .el-button.is-circle {
            padding: 6px;
          }
          .el-icon-download {
            font-size: 20px;
          }
        }
      }
    }
    .title-modified {
      display: inline-block;
      width: 11px;
      height: 11px;
      background-color: #387bed;
    }
  }
}
</style>
