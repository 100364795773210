<script>
import {getProjectDetail} from "@/api/map/index";
import {getHangarList} from '@/api/hangar/index';

export default {
    data() {
        return {
            // 机库列表数据
            hangarList: []
        }
    },
    methods: {
        // 获取机库列表并绘制
        async getHangarData(data) {
            this.clearHangar(); // 绘制之前清空一次
            let uavList = [];
            if (!this.firstPath) {
                if (data && data.equipmentItem && data.equipmentItem.airs) {
                    uavList = data.equipmentItem.airs.equipmentId ? [data.equipmentItem.airs.equipmentId] : [];
                } else {
                    uavList = data.equipmentId ? [data.equipmentId] : [];
                }
            } else {
                switch (this.firstPath) {
                    case '/mission-center':
                        uavList = data.uavList || [];
                        break;
                    case '/forest-grass':
                    case '/oil-gas':
                        const projectDetail = await getProjectDetail({id: data.flyProjectID});
                        if (projectDetail.data.code === 200) {
                            uavList = projectDetail.data.data ? (projectDetail.data.data.uavList || []) : [];
                        }
                        break;
                    case '/emergency':
                        uavList = data.equipmentIds || [];
                        break;
                }
            }
            if (uavList.length) {
                getHangarList(uavList).then(res => {
                    if (res.data.code == 200) {
                        this.hangarList = res.data.data || [];
                        this.initHangar(res.data.data || []);
                    }
                });
            }
        }
    }
}
</script>