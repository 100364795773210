var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "el-dialog",
        {
          staticClass: "task-video-dialog",
          attrs: {
            title: _vm.$t("mission.flightMissionDetails"),
            visible: _vm.videoDialogVisible,
            width: "80%",
            fullscreen: true,
            "append-to-body": true,
            "before-close": _vm.videoCloseDialog,
            "destroy-on-close": "",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "taskPlayBack-header" }, [
              _c("div", [
                _c("span", { staticClass: "title-modified" }),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("mission.MissionDetails")) +
                    "\n        "
                ),
              ]),
              _c("div", { staticClass: "taskPlayBack-header-content" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      "任务名称: " + _vm._s(_vm.taskPlayBackData.taskName)
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      "航线名称: " + _vm._s(_vm.taskPlayBackData.taskRouteName)
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("span", [_vm._v("任务类型: " + _vm._s(_vm.taskType))]),
                  _c("span", [
                    _vm._v(
                      "设备名称: " + _vm._s(_vm.taskPlayBackData.equipmentNames)
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("span", [
                    _vm._v(
                      "开始时间: " + _vm._s(_vm.taskPlayBackData.flyBeginTime)
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      "结束时间: " + _vm._s(_vm.taskPlayBackData.flyFinishTime)
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("span", [
                    _vm._v(
                      "飞手: " + _vm._s(_vm.taskPlayBackData.operatorName)
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "taskPlayBack-video" }, [
              _c("div", { staticClass: "taskPlayBack-video-title" }, [
                _c("span", { staticClass: "title-modified" }),
                _vm._v("\n          任务回放\n        "),
              ]),
              _c(
                "div",
                { staticClass: "taskVideoSelect" },
                [
                  [
                    _c("span", [_vm._v("请选择播放源： ")]),
                    _c("el-cascader", {
                      staticStyle: { width: "300px" },
                      attrs: { options: _vm.options },
                      on: { change: _vm.historyVideoChange },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    }),
                  ],
                ],
                2
              ),
              _c("div", { staticClass: "taskVideoInformation" }, [
                _c("div", [
                  _vm._v("视频名称：" + _vm._s(_vm.historyVideoData.videoName)),
                ]),
                _c("div", [
                  _vm._v("播放地址：" + _vm._s(_vm.historyVideoData.url)),
                ]),
                _c("div", [
                  _vm._v("视频文件大小：" + _vm._s(_vm.historyVideoData.size)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "videoPlayer" }, [
              _c(
                "div",
                {
                  staticClass: "videoPlayer-inner",
                  on: {
                    mouseover: function ($event) {
                      _vm.isDownload = true
                    },
                    mouseout: function ($event) {
                      _vm.isDownload = false
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isDownload && _vm.historyVideoUrl,
                          expression: "isDownload && historyVideoUrl",
                        },
                      ],
                      staticClass: "download",
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          type: "info",
                          circle: "",
                          icon: "el-icon-download",
                        },
                        on: { click: _vm.downLoadVideo },
                      }),
                    ],
                    1
                  ),
                  _c("img", {
                    attrs: { height: "100%", src: "/img/live-bg.png", alt: "" },
                  }),
                  _c("xg-player", {
                    ref: "videoLive",
                    staticClass: "videoPlayer-xg-player",
                    attrs: {
                      videoType: _vm.type,
                      videoUrl: _vm.historyVideoUrl,
                      screen: false,
                      show: true,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }