<template>
  <basic-container class="form-layer-eq">
    <avue-crud
      :option="option"
      :pagination="false"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :before-open="beforeOpen"
      ref="crud"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
    </avue-crud>
  </basic-container>
</template>

<script>
import { getUseEquipmentList } from "@/api/phugiacloud/projectManagement";
import { getRoutes } from "@/api/phugiacloud/projectTask";
import { getProjectDetail } from "@/api/project/index";
export default {
  name: 'FormLayer',
  props: {
    taskId: {
      type: String,
      default() {
        return '#'
      }
    },
    type: {
      type: String,
      default() {
        return ''
      }
    },
    equipType: {
      type: String,
      default() {
        return ''
      }
    },
    column: {
      type: Array,
      default() {
        return []
      }
    },
    projectId: {
      type: String,
      default() {
        return '#'
      }
    },
    teamId: {
      type: String,
      default() {
        return '#'
      }
    },
  },
  data() {
    return {
      loading: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      data: [],
      query: {},
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        menu: false,
        tip: false,
        border: false,
        searchShow: true,
        searchMenuSpan: 6,
        searchLabelWidth: 90,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        selection: true,
        searchBtnText: '搜 索',
        dialogClickModal: false,
        excelBtn: false,
        columnBtn: false,
        editBtn: false,
        column: this.column
      }
    }
  },
  watch: {
    column: {
      deep: true,
      handler(n, o) {
        if (n) {
          this.loading = true
          this.option.column = this.column
          this.onLoad(this.page, params);
        }
      }
    }
  },
  methods: {
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      if (list.length > 1) {
        this.$message.warning("只允许单选！")
      }
      let tempArr = list.slice(1)
      if (tempArr.length !== 0) {
        tempArr.forEach((item) => {
          this.$refs.crud.toggleRowSelection(item, false)
        })
      }
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      const routeParam = {
        taskSiteId: this.taskId
      }
      if (this.type === 'route') {
        getRoutes(
          page.currentPage,
          page.pageSize,
          Object.assign(routeParam, this.query)
        ).then((res) => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.loading = false
          this.selectionClear();
        })
      } else {
        getUseEquipmentList({ 
          deptId: this.teamId,
          projectId:this.projectId,
          equipmentType: this.equipType
        }).then((res) => {
          const data = res.data.data;
          this.data = data;
          this.loading = false
          this.selectionClear();
        })
      }
    },
    getSelection(){
      return this.selectionList
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getProjectDetail(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    }
  }
}
</script>

<style lang="scss">
</style>